<template>
  <div :class="prefixCls">
    <div ref="editor" class="editor-wrapper"></div>
  </div>
</template>

<script>
import WEditor from 'wangeditor'
import { getToken } from '@/api/upload'
import OSS from 'ali-oss'
import Vue from 'vue'
import ElementUI from 'element-ui'
import { ElementTiptapPlugin } from 'element-tiptap'
import 'element-ui/lib/theme-chalk/index.css'
// import element-tiptap 样式
import 'element-tiptap/lib/index.css'

Vue.use(ElementUI)
// 安装 element-tiptap 插件
Vue.use(ElementTiptapPlugin, {
  lang: 'zh', // 见 i18n
  spellcheck: true // 可被 editor 同名 prop 重写
})

export default {
  name: 'WangEditor',
  props: {
    prefixCls: {
      type: String,
      default: 'ant-editor-wang'
    },
    // eslint-disable-next-line
    value: {
      type: String,
      default: ''
    },
    // 自定义配置
    config: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      editor: null,
      // editorContent: null,
      menus: [
        'head',
        'bold',
        'underline',
        // 'italic',
        // 'strikeThrough',
        'list',
        'image',
        // 'table',
        'undo',
        'redo',
        // 'link',
        // 'quote',
        'justify',
        'splitLine'
      ],
      tokenInfo: {
        'region': '',
        'AccessKeyId': '',
        'AccessKeySecret': '',
        'SecurityToken': '',
        'bucket': '',
        'domain': ''
      }
    }
  },
  watch: {
    value (val) {
      // this.editorContent = val
      this.editor.txt.html(val)
    }
  },
  mounted () {
    this.initEditor()
  },
  created () {
    this.getTokenApi()
  },
  methods: {
    initEditor () {
      this.editor = new WEditor(this.$refs.editor)
      // 配置是否展示全屏
      this.editor.config.showFullScreen = false
      // 配置菜单栏提示(up/down)
      this.editor.config.menuTooltipPosition = 'up'
      // this.editor.onchangeTimeout = 200
      this.editor.config.onchange = (html) => {
        // this.editorContent = html
        this.$emit('change', html)
      }
      this.editor.config.onfocus = function () {
        console.log('on focus')
      }
      // this.editor.config.uploadImgShowBase64 = true
      this.editor.config.zIndex = 100
      // this.editor.config.showLinkImg = false //隐藏网络上传图片入口
      // 忽略粘贴内容中的图片
      this.editor.config.pasteIgnoreImg = true
      // 自定义菜单
      this.editor.config.menus = this.menus
      // 自定义Alert
      this.editor.config.customAlert = this.customAlert
      // 自定义图片上传地址
      // this.editor.config.uploadImgServer = uploadImageUrl
      // 自定义上传文件名
      this.editor.config.uploadFileName = 'file'
      // 自定义上传
      this.editor.config.customUploadImg = this.customUpload
      // 图文粘贴时, 忽略其中的图片
      this.editor.config.pasteIgnoreImg = true
      // 上传大小
      this.editor.config.uploadImgMaxSize = 50 * 1024 * 1024
      // 是否显示网络图片
      this.editor.config.showLinkImg = false
      this.editor.config.height = 700

      if (this.config) {
        // 自定义配置
        for (const index in this.config) {
          this.editor.config[index] = this.config[index]
        }
      }
      this.editor.create()
      if (this.value) {
        // 内容默认值
        this.editor.txt.html(this.value)
      }
      this.$emit('init', this.editor)
    },
    customUpload (resultFiles, insertImgFn) {
      if (resultFiles.length > 0) {
        resultFiles.forEach(file => {
          this.handleUploadImg(file, insertImgFn)
        })
      }
    },
    async handleUploadImg (file, insertImgFn) {
      const _this = this
      var replaceContent = ''
      const imgClass = 'imgages-' + Math.random() * Math.pow(10, 18)
      const defaultImgUrl = '/uploading.gif'
      this.editor.cmd.do('insertHTML', `<img class="${imgClass}" src="${defaultImgUrl}" style="max-width: 40px;" />`)
      const patterns = new RegExp(`<img[^>]*class="${imgClass}"[^>]*>`, 'gi')
      var fileArr = file.name.split('.')
      var fileType = fileArr[fileArr.length - 1]

      var myDate = new Date()
      var year = myDate.getFullYear()
      var month = myDate.getMonth() + 1
      var day = myDate.getDate()
      if (month >= 1 && month <= 9) {
        month = '0' + month
      }

      if (day >= 0 && day <= 9) {
        day = '0' + day
      }

      var randomStr = this.getRandomStr(14)
      var keyimg = 'image/' + year + '/' + month + day + '/' + randomStr + '.' + fileType
      const client = new OSS({
        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
        region: _this.tokenInfo.region,
        // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
        accessKeyId: _this.tokenInfo.AccessKeyId,
        accessKeySecret: _this.tokenInfo.AccessKeySecret,
        stsToken: _this.tokenInfo.SecurityToken,
        // 填写Bucket名称，例如examplebucket。
        bucket: _this.tokenInfo.bucket,
        secure: true
      })

      const headers = {
        // 指定该Object被下载时的网页缓存行为。
        'Cache-Control': 'no-cache',
        // 指定该Object被下载时的名称。
        'Content-Disposition': keyimg,
        // 指定该Object被下载时的内容编码格式。
        'Expires': 1000,
        'Content-Encoding': 'utf-8',
        // 指定Object标签，可同时设置多个标签。
        'x-oss-tagging': 'image=1',
        // 指定初始化分片上传时是否覆盖同名Object。此处设置为true，表示禁止覆盖同名Object。
        'x-oss-forbid-overwrite': 'true'
      }

      const options = {
        // 获取分片上传进度、断点和返回值。
        progress: (p, cpt, res) => {
          console.log(p)
        },
        // 设置并发上传的分片数量。
        parallel: 4,
        // 设置分片大小。默认值为1 MB，最小值为100 KB。
        partSize: 1024 * 1024,
        headers
      }
      console.log(client, options)

      try {
        const result = await client.multipartUpload(keyimg, file, {
          ...options
        })

        if (result.name) {
          console.log(_this.tokenInfo.domain + result.name)
          const imgUrl = _this.tokenInfo.domain + result.name
          replaceContent = `<img src="${imgUrl}" style="max-width: 100%;" />`
          // insertImgFn(imgUrl)
        } else {
          this.customAlert('上传失败', 'error')
        }
        const content = _this.editor.txt.html()
        const txt = content.replace(patterns, replaceContent)
        this.editor.txt.html(txt)
        console.log(result)
      } catch (e) {
        const content = _this.editor.txt.html()
        const txt = content.replace(patterns, replaceContent)
        this.editor.txt.html(txt)
        console.log(e)
      }
    },
    customAlert (s, t) {
      switch (t) {
        case 'success':
          this.$message.success(s)
          break
        case 'info':
          this.$message.info(s)
          break
        case 'warning':
          this.$message.warning(s)
          break
        case 'error':
          this.$message.error(s)
          break
        default:
          this.$message.info(s)
          break
      }
    },
    getTokenApi () {
      const _this = this
      getToken().then((res) => {
        if (res.errorCode === 0) {
          _this.tokenInfo = res.result
          console.log('tokenInfo', _this.tokenInfo)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
      })
    },
    getRandomStr (len) {
      var str = ''
      for (var i = 0; i < len; i++) {
        str += Math.random().toString(36).substr(2)
      }
      return str.substr(0, len)
    }
  }
}
</script>

<style lang="less" scoped>
.ant-editor-wang {
  .editor-wrapper {
    text-align: left;
  }
}
</style>
